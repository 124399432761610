footer {
  text-align: center;
  margin-top: 0.875rem;
  padding: 0.875rem 0;
  p {
    margin: 0;
  }
  .container {
    padding-top: 1.5rem;
    border-top: 1px solid $gray-300;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    position: relative;
  }
  img {
    position: absolute;
    width: 129px;
    left: 0;
  }
  p {
    padding: 0 5px;
    margin-bottom: 0;
    &:after {
      content: '|';
      margin-left: 5px;
    }
    &:last-child:after {
      content: '';
    }
  }
  @include media-breakpoint-down(md) {
    .container {
      flex-direction: column;
    }
    img {
      position: static;
      margin-bottom: 1rem;
    }
    p:after {
      content: '';
    }
  }
}
