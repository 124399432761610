// Terms and Conditions
$terms-and-conditions-border-color: $brand-tertiary;
$terms-and-conditions-bg: $gray-100;
$tac-fade-box-visibility: none;

// Terms and Conditions field in FormDesigner
.terms-and-conditions-text {
  textarea {
    height: 150px;
  }
}

// Terms and Conditions field in Dynamic Form
.terms-and-conditions-field {
  .tac-content-wrapper {
    border: 1px solid $terms-and-conditions-border-color;
    background-color: $terms-and-conditions-bg;
    color: #333;
    .checkbox {
      border-bottom: none;
      label:before {
        background: white;
      }
      &.checkbox-checked {
        background-color: $terms-and-conditions-bg;
        border-left: 5px solid transparent;
      }
    }
  }
  .top-wrapper {
    padding: 20px 10px 20px 20px;
    padding-bottom: 0;
    border-bottom: none;
    position: relative;
  }
  .fade-box {
    display: $tac-fade-box-visibility;
    position: absolute;
    width: 100%;
    margin: 0;
    bottom: 0;
    left: 0;
    height: 30px;
    background-image: linear-gradient(to bottom, rgba(251, 251, 251, 0), #eee);
  }
  .tac-content {
    max-height: 285px;
    overflow: scroll;
    white-space: pre-wrap;
    padding: 0 10px 0 0;
    /**
    * Hack to fix that Firefox doesn't care about padding-bottom when using overflow: scroll
    * https://stackoverflow.com/questions/29986977/firefox-ignores-padding-when-using-overflowscroll
    */
    &:after {
      content: '';
      height: 30px;
      display: block;
    }
  }
  input[type='checkbox']:focus {
    width: auto;
  }
}
