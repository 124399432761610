// Form Designer
.form-edit {
  .form-edit-top-buttons {
    margin-bottom: 1rem;
    .btn {
      @extend .btn-sm;
    }
  }

  .col-form-label,
  .form-control-plaintext {
    padding-top: 0;
    padding-bottom: 0;
  }

  .form-edit-top-buttons {
    margin: 2rem 0 1rem 0;
  }
}
.form-designer-wrapper {
  h1,
  h2,
  h3,
  h3 {
    margin-bottom: 0;
  }

  .btn {
    @extend .btn-sm;
  }

  .design-title {
    font-size: $h2-font-size;
    font-weight: $headings-font-weight;
  }
  h4 {
    font-weight: 600;
  }
  .panel-heading {
    .form-group {
      margin-bottom: 1rem;
    }
  }

  .pull-left {
    float: left;
  }
  .pull-right {
    float: right;
  }

  .col-sm-6:not(.designer-preview) {
    .btn-primary {
      @extend .btn-sm;
    }
    // Make the FormDesigner input fields as small as the SearchFields
    // @include slim-form;

    // Dont use font awesome styling on checkboxes as they have the "wrong" html
    .checkbox {
      input {
        display: inline-block;
        height: auto;
      }
      label {
        padding: 1rem 0;
      }
      label:before {
        display: none;
      }
    }
    // The orientation select takes up extra space below
    .select .form-control-feedback {
      display: none;
    }
  }
  .panel-title {
    & > .btn {
      margin-right: 0.5rem;
    }
  }
  .btn-row .btn {
    margin-top: 0;
  }
  .panel-body {
    margin-top: 2rem;
  }
  .panel-footer {
    margin-bottom: 3rem;
  }
  .question {
    margin-bottom: 2rem;
  }
  .form-control-static {
    margin-bottom: 0;
  }
}

.form-designer-options-modal {
  input[type='radio'] {
    margin-right: 0.3rem;
  }
  table input {
    height: 2.3rem;
    padding: 0.5rem;
    font-size: 1rem;
  }
  &.modal-footer {
    .btn {
      @extend .btn-sm;
    }
  }
}
