.fundadvice-hero {
  margin-top: -130px; // Same as content-class padding and margin
  padding: 6.9rem;
  background-color: $gray-200;
}

.fund-advice-overview-links {
  margin-top: -70px;
  margin-bottom: 6.42rem;
}

.fund-portfolio-fund {
  margin-bottom: 1rem;
}
.fund-portofolio-fund-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  h4 {
    color: $brand-primary;
    font-weight: 600;
    margin-bottom: 0;
  }
}
.fundadvice-header {
  margin-bottom: 67px;
}

.fundadvice-header-right {
  display: inline-flex;
  align-items: center;
  flex-direction: row;
  label,
  p {
    margin-bottom: 0;
  }
  .toggle {
    margin: 0 50px 0 20px;
  }
}

.savings-analysis {
  tspan {
    font-size: 10px;
  }
  .sum-boxes {
    p {
      margin-bottom: 0;
    }
  }
  .sumbox-line {
    width: 30px;
    height: 3px;
    background-color: $brand-primary;
    display: inline-block;
  }
}

// Category Admin
.category-edit-icon-highlight-wrapper {
  align-self: flex-end;
  margin-bottom: 10px;
}

.fund-advice-start-portfolios {
  margin-top: 6.42rem;
}

.fund-advisor-opinion-line {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  margin-bottom: 1rem;
  .fa {
    display: inline-block;
    margin-right: 20px;
    margin-top: 5px;
    font-size: 0.875rem;
  }
  .fund-advisor-opinion {
    font-size: 1rem;
  }
}

// Summary/result page
.fundadvise-summary {
  display: relative;
  .card-extra-space .card-body {
    padding-left: 88px;
    padding-right: 88px;
  }

  .recharts-wrapper {
    margin: 50px auto 0;
  }
  .back-link {
    margin-bottom: 2rem;
  }
}

.recharts-middle {
  .recharts-wrapper {
    margin-left: auto;
    margin-right: auto;
  }
}

// Fund list on result pages
.spread-result-list {
  .spread-result-header {
    font-weight: 600;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 25px;
    p {
      margin-bottom: 0;
    }
    .header-sum {
      margin-right: 6%;
    }
  }
  .spread-result-accordion {
    margin-bottom: 1.5rem;
  }
  .spread-result-accordion-header {
    display: flex;
    flex-direction: row;
    align-items: center;
  }
  .spread-title {
    width: 68%;
    display: flex;
    flex-direction: row;
    .circle {
      width: 14px;
      height: 14px;
      border-radius: 100%;
      background-color: $brand-primary;
      margin-right: 1rem;
      flex-shrink: 0;
    }
    p {
      margin-bottom: 0;
      margin-right: 10px;
      line-height: 1rem;
      font-weight: 600;
    }
    a {
      line-height: 1rem;
    }
  }
  .spread-rating {
    width: 18%;
    margin-left: 1rem;
  }
  .spread-percent {
    width: 7%;
    margin-left: 1rem;
    font-size: 0.875rem;
  }
  .spread-sum {
    width: 17%;
    margin-left: 1rem;
    font-size: 0.875rem;
  }
  .spread-result-accordion-content {
    display: none;
    margin-top: 1rem;
  }
  .open {
    .spread-result-accordion-content {
      display: block;
    }
  }
  .fund-advisor-opinion {
    font-size: 0.875rem;
  }
}

// Active flow
.fundadvise-active-form {
  .accordion-header {
    font-size: 1.14rem;
  }
  .accordion {
    border-bottom: none;
    margin-bottom: 2rem;
  }
}

.active-fund-portfolio {
  .fa-external-link {
    font-size: 10px;
    margin-left: 0.8rem;
  }
  .form-control {
    height: 40px;
    width: 90px;
    padding-top: 1rem;
    padding-bottom: 1rem;
    text-align: center;
  }
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  th:first-child {
    width: 99%;
  }

  input[type='number'] {
    -moz-appearance: textfield;
  }

  tfoot td:first-child {
    padding-left: 0;
  }
}

.portfolio {
  .fa-external-link {
    font-size: 10px;
    margin-left: 0.8rem;
  }
  .form-control {
    height: 40px;
    width: 132px;
    padding-right: 32px;
    padding-left: 0px;
    text-align: center;
  }
  .sum {
    width: 124px;
  }
  .percent {
    width: 80px;
  }
}

.add-fund-modal {
  .form-inline {
    display: flex;
    justify-content: space-between;
  }
  .add-fund-modal-fund-search-wrapper {
    position: relative;
  }
  .add-fund-modal-category-search {
    margin-right: 0;
  }
  .radio label:before {
    margin-right: 0;
  }
}

.fund-search-btn {
  position: absolute;
  right: 25px;
  top: 42px;
  font-size: 20px;
  &.fund-search-btn-no-label {
    top: 6px;
  }
}

.fund-advice-summary-header {
  padding: 40px 50px;
}

.fund-advice-summary-header,
.fundadvice-header {
  a {
    font-weight: 500;
    text-transform: uppercase;
    margin-right: 15px;
    font-size: 0.75rem;
  }
}

.import-fund-excel {
  .file-uplod-control-label {
    display: none;
  }
  ul {
    list-style-position: inside;
  }
  .accordion {
    border-bottom: none;
  }
  .accordion-body {
    max-height: 300px;
    overflow-y: scroll;
  }
}

///style for slider with input fields
.rangslider-container {
  display: grid;
  grid-template-columns: 3fr 3fr;
  width: 100%;
}

.rangeslider-unit {
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  padding-right: 1rem;
  font-size: 0.875rem !important; // 14px
}

.rangeslider-inputs-container {
  display: flex;
  justify-content: flex-end;
  gap: 10px;
}

.input-container {
  display: flex;
  justify-content: flex-end;
  gap: 0.25rem;
  position: relative;
}

///for case with more then 1 input
.rangeslider-input {
  padding: 0rem 2rem 0rem 1rem;
  height: 2.5rem;
  font-size: 0.875rem;
  text-align: end;
  width: 134px;
  &.amount {
    width: 131px;
  }
  &.percent {
    width: 80px;
  }
}
