.thankyou-page {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 6.8rem 0;
  border: 1px solid $gray-300;
  i {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 2rem;
    border-radius: 100%;
    width: 70px;
    height: 70px;
    background-color: $brand-success;
    background: linear-gradient($green1, $green2);
    color: $white;
    margin-bottom: 1.5rem;
  }
  h3 {
    font-weight: 500;
  }
}

.contact .thankyou-page {
  border: none;
}
