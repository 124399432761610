// Background Colors
.bg-gray-100 {
  background-color: $gray-100;
}
.bg-gray-200 {
  background-color: $gray-200;
}

// Font sizes. From xl and up use the following formula: 1.5 + x * 0.25rem
.text-xs {
  font-size: 0.75rem;
}
.text-sm {
  font-size: 0.875rem !important; // 14px
}
.text-base {
  font-size: 1rem;
}
.text-lg {
  font-size: 1.125rem;
}
.text-xl {
  font-size: 1.5rem;
}
.text-1xl {
  font-size: 1.75rem;
}
.text-2xl {
  font-size: 2rem;
}

//height

.h-2-5-rem {
  height: 2.5rem;
}

// Font-weight
.fw-400,
.font-weight-normal {
  font-weight: 400;
}
.fw-500 {
  font-weight: 500;
}
.fw-600 {
  font-weight: 600;
}
.fw-700 {
  font-weight: 700;
}

// Sizing, multiples of 4
.w-4 {
  width: 1rem;
}
.h-4 {
  height: 1rem;
}

// Margins
.mb-2-5-rem {
  margin-bottom: 2.5rem;
}

//padding
.pr-2-rem {
  padding-right: 2rem;
}
.pr-1-8-rem {
  padding-right: 1.8rem;
}

.link-disabled {
  pointer-events: none;
  cursor: default;
}
