// .account-info {
//   flex-direction: row;
//   align-items: center;
//   justify-content: center;
// }

.account-type-icon {
  background-color: $brand-primary;
  border-radius: 100%;
  width: 30px;
  height: 30px;
  color: #fff;
  font-style: normal;
  font-size: 11px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  margin-left: 10px;
  font-weight: 600;
  &.type-fk {
    background-color: $brand-primary;
  }
  &.type-isk {
    background-color: $brand-secondary;
  }
}
