$fund-info-padding-y: 1rem;
$fund-info-margin-bottom: 1.25rem;

.fundinfo-stats {
  padding: $fund-info-padding-y 4.875rem;
  margin-bottom: $fund-info-margin-bottom;
  background-color: $white;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-wrap: wrap;
  @include media-breakpoint-down(lg) {
    padding-left: 2rem;
    padding-right: 2rem;
    .fundinfo-stats-block {
      margin: 0 1rem 1rem;
    }
  }
  @include media-breakpoint-down(md) {
    justify-content: flex-start;
  }
  h3 {
    font-size: 11px;
    font-weight: 300;
    margin-bottom: 7px;
  }
  a {
    font-size: 11px;
  }
}

.fundinfo-sustainability {
  margin-left: 4px;
  margin-right: 4px;
}
.divider {
  border-bottom: 1px solid;
  margin-bottom: 1rem;
  color: $gray-300;
}

.green-check {
  color: $brand-success;
}
.red-cross {
  color: $brand-danger;
}
.gray-question {
  color: $gray-800;
}

.italic {
  font-style: italic;
}

.sustainabilty-true {
  line-height: 40px;
  height: 40px;
  width: 190px;
  background-color: $brand-tertiary;
  margin-bottom: 10px;
}

.sustainabilty-bkg-true {
  margin-left: 10px;
  margin-right: 10px;
  background-color: $brand-tertiary;
  color: $brand-primary;
}

.sustainabilty-false {
  line-height: 40px;
  height: 40px;
  width: 210px;
  background-color: $gray-300;
  margin-bottom: 10px;
}
.sustainabilty-bkg-false {
  margin-right: 10px;
  margin-left: 10px;
  background-color: $gray-300;
}

.sustainabilty-null {
  line-height: 40px;
  height: 40px;
  width: 250px;
  background-color: $gray-100;
  margin-bottom: 10px;
}

.sustainabilty-bkg-null {
  margin-right: 10px;
  margin-left: 10px;
  background-color: $gray-100;
}

.fundinfo-sustainability-approval-true {
  color: $brand-primary;
}

.fundinfo-sustainability-approval-false {
  visibility: hidden;
}

.fundinfo-sustainability-article-9 {
  color: $sfdr-article-9-color;
}

.fundinfo-sustainability-article-8 {
  color: $sfdr-article-8-color;
}

.fundinfo-sustainability-article-0 {
  visibility: hidden;
}

.indecap-rating {
  .indecap-rating-item {
    display: inline-block;
    height: 15px;
    width: 15px;
    border-radius: 100%;
    background-color: $gray-300;
    margin-right: 4px;
    &.active {
      background-color: $brand-primary;
    }
  }
}

.mstar-rating {
  .mstar-rating-item {
    font-size: 15px;
    color: $gray-300;
    &.active {
      color: $black;
    }
  }
}

.riskclass {
  h3 {
    margin-bottom: 2px;
  }
  .riskclass-gradient {
    height: 5px;
    background: linear-gradient(90deg, #36d036 0, #fdc50a 25%, #fd840d 65%, #fa0206);
  }
  .riskclass-item {
    height: 15px;
    width: 15px;
    border-radius: 100%;
    background-color: $white;
    font-size: 11px;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    &.active {
      background-color: $black;
      color: $white;
      position: relative;
      &:after {
        position: absolute;
        bottom: -3px;
        left: 5px;
        width: 0;
        height: 0;
        border: 3px solid transparent;
        content: '';
        border-top-color: #262626;
        border-left-color: #262626;
        transform: rotate(-135deg) translate(1px, 1px);
      }
    }
  }
}
