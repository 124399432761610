$checkbox-height: 20px;

// Checkbox and Radio
input[type='checkbox'],
input[type='radio'] {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  line-height: normal;
  box-sizing: border-box;
  padding: 0;
  outline: none !important;
  margin: 0;
  margin-right: $checkbox-height / 3;

  &::after {
    cursor: pointer;
    content: '\f068'; // Need to have some icon as default, else checkboxes "jump" when being checked.
    font-family: 'Font Awesome 5 Pro';
    color: transparent;
    display: inline-block;
    width: $checkbox-height;
    height: $checkbox-height;
    // line-height: 14px;
    line-height: 20px;
    font-size: 12px;
    font-weight: 600; // fas
    position: relative;
    transition:
      $btn-transition,
      color 0s ease-in-out; // We remove color transition, else you briefly see [-] when unchecking.
    border: 1px solid $gray-800;
    text-align: center;
    // padding-top: 2px;
  }

  &:disabled::after,
  &.disabled::after {
    cursor: not-allowed;
    background-color: $input-disabled-bg;
    border-color: $input-border-color;
  }
}

input[type='checkbox'] {
  &::after {
    border-radius: 2px;
  }

  &.indeterminate::after {
    background-color: $brand-primary;
    color: $light;
  }

  &:checked::after {
    content: '\f00c';
    background-color: $brand-primary;
    color: $light;
    border-color: $brand-primary;
  }

  &:checked:disabled::after,
  &:checked.disabled::after {
    color: $gray-700;
  }
}

input[type='radio'] {
  &::after {
    border-radius: 50%;
    font-size: 20px;
    // font-size: 6px;
    vertical-align: bottom;
  }

  &:checked::after {
    content: '\f192';
    // color: $light;
    // background-color: $brand-primary;
    color: $brand-primary;
    border-color: $brand-primary;
    border: none;
  }

  &:checked:disabled::after,
  &:checked.disabled::after {
    color: $gray-700;
  }
}

.checkbox,
.radio {
  label {
    cursor: pointer;
    margin-bottom: 0.5rem;
  }
}

// Checkboxes and radio buttons have a different style in our dynamic forms
.dynamic-form {
  .radio,
  .checkbox {
    border-top: 1px solid $gray-300;
    border-left: 5px solid transparent;
    label {
      padding: 1.6rem 1rem;
    }
    input {
      margin-right: 1.3em;
    }
    &:last-child {
      border-bottom: 1px solid $gray-300;
    }
  }
  .radio-checked + .radio,
  .checkbox-checked + .checkbox {
    border-top: none;
  }
  .checkbox.checkbox-checked,
  .radio.radio-checked {
    background-color: $gray-200;
    border-bottom: 1px solid $gray-300;
    border-left: 5px solid $brand-primary;
    label:before {
      // The circle in the middle should be white.
      border-radius: 100%;
      background-color: $white;
    }
  }
}
