.help-block {
  @extend .invalid-feedback; // Extend with bootstrap validation
  display: block;
  list-style: none;
  margin: 0;
  margin-top: calc($spacer / 2);
  padding: 0;
}

.form-control-feedback {
  display: none;
}

.has-error {
  .help-block li {
    color: $brand-danger;
    font-weight: 500;
  }
}

// .has-success input {
//   @extend .is-valid;
// }

// .has-error input {
//   @extend .is-invalid;
// }

// .has-success textarea {
//   @extend .is-valid;
// }

// .has-error textarea {
//   @extend .is-invalid;
// }

// .has-success select {
//   @extend .is-valid;
// }

// .has-error select {
//   @extend .is-invalid;
// }

// .form-control-feedback {
//   display: none;
// }
