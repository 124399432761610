.user-account-summary-boxes {
  border-top: solid 1px $gray-300;
  border-bottom: 1px solid $gray-300;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-left: -2.5rem;
  margin-right: -2.5rem;
  margin-bottom: 2.5rem;
  .user-account-summary-box {
    flex: 1;
    text-align: left;
    border-right: 1px solid $gray-300;
    padding: 2rem 2.5rem;
    &:last-child {
      border-right: none;
    }
  }
  .box-wrapper {
    display: inline-block;
    text-align: left;
  }
  .title,
  .value {
    display: block;
  }
  .value {
    font-size: 1.5rem;
    font-weight: 500;
    color: $black;
  }
  @include media-breakpoint-down(md) {
    margin-left: -1rem;
    margin-right: 1rem;
    .user-account-summary-box {
      flex-basis: 50%;
      &:nth-child(1n) {
        border-right: 1px solid $gray-300;
      }
      &:nth-child(2n) {
        border-right: none;
      }
      &:nth-child(1),
      &:nth-child(2) {
        border-bottom: 1px solid $gray-300;
      }
    }
  }
  @include media-breakpoint-down(sm) {
    .user-account-summary-box {
      flex-basis: 100%;
      text-align: left;
      padding-left: 1rem;
      &:nth-child(1n) {
        border-right: none;
        border-bottom: 1px solid $gray-300;
      }
    }
  }
}
