$register-bottom-height: 75px;

.register {
  .form-name {
    @include chapter-heading();
  }
  h4.panel-title {
    // TODO: This section header should be duplicated. The below style will place it in the bottom.
    // position: fixed;
    // z-index: 11;
    // bottom: 10px;
    // left: 0;
    // width: 100%;
    // text-align: center;
    display: none;
  }
  .progress {
    position: fixed;
    z-index: 10;
    bottom: 75px;
    left: 0;
    width: 100%;
    border-radius: 0;
    height: 5px;
  }
  .userflow-prev-btn {
    position: fixed;
    z-index: 12;
    color: $black;
    bottom: 0px;
    left: 0px;
    background: transparent;
    border: none;
    font-size: 24px;
  }
  .userflow-next-btn {
    width: 100%;
  }
  .userflow-prev-btn,
  .userflow-next-btn {
    margin: 0;
  }
  .form-group {
    &.text-field {
      margin-bottom: 2.5rem;
    }
    &:not(.text-field) {
      margin-bottom: 4rem;
    }
    @include media-breakpoint-down(sm) {
      &:last-child {
        margin-bottom: 1rem;
      }
    }
  }
  .form-control-plaintext {
    padding: 0;
  }
  .repeatable-section .form-group {
    margin-bottom: 1.5rem;
  }
  .flow-action-error {
    margin-top: 1rem;
  }
}

// Safari doesn't do margin bottom on flex items so we add a filler so that the bottom wont end up on top the content.
.register-filler {
  height: $register-bottom-height;
}

.register-bottom,
.white-footer {
  z-index: 10;
  height: $register-bottom-height;
  width: 100%;
  position: fixed;
  bottom: 0;
  box-shadow: 0 -10px 20px -5px rgba(95, 3, 201, 0.05);
  background-color: $white;
  padding-top: 25px;
  &.not-fixed {
    position: static;
  }
}

.register-content {
  background-color: #fff;
  max-width: 653px;
  min-height: 600px;
  margin: 2rem auto 0;
  padding: 2.86rem;
  display: flex;
  flex-direction: column;
  margin-bottom: 90px;

  h1,
  h3 {
    text-align: center;
    margin-bottom: 2.2rem;
  }
  .btn,
  .button-row {
    margin-top: auto;
    font-weight: 600;
  }
  @include media-breakpoint-down(sm) {
    min-height: 0;
    h2 {
      text-align: center;
    }
  }
}

.register-start-text-wrapper {
  @include media-breakpoint-down(sm) {
    margin-bottom: 3.2rem;
  }
}
.register-start-text {
  img,
  .fas {
    margin-right: 2rem;
    display: inline-block;
  }
  .fas {
    width: 112px;
    color: $brand-primary;
  }
  a {
    font-weight: 500;
  }
  display: flex;
  flex-direction: row;
  align-items: center;
  @include media-breakpoint-down(sm) {
    img,
    .fas {
      width: 50px;
      margin-right: 1rem;
    }
    .fas {
      font-size: 50px;
    }
    &.mobile-dir-col {
      flex-direction: column;
      img,
      .fas {
        margin-bottom: 1rem;
      }
    }
  }
}

// First page in onboarding flow
.register-start-content-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 1rem;
  .register-start-box {
    text-align: center;
    width: 31%;
  }
  .register-start-image-wrapper {
    background-color: $gray-200;
    height: 150px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 0.6rem;
  }
  @include media-breakpoint-down(sm) {
    flex-direction: column;
    align-items: center;
    .register-start-box {
      width: 150px;
    }
  }
}

// Share results page
.register-shares {
  .nav-tabs {
    display: flex;
    justify-content: center;
  }
  .nav-item {
    text-align: center;
  }
  .recharts-wrapper {
    display: inline-block;
  }
  .fees-divider {
    border-bottom: 1px solid $brand-tertiary;
    margin-bottom: 1rem;
  }
}

// Shares error page
.register-shares-error {
  display: flex;
  flex-direction: column;
  flex: 1;
  justify-content: space-between;
  .warning-icon {
    font-size: 70px;
    margin-bottom: 1.45rem;
  }
  .card-body {
    text-align: center;
  }
}

.register-agreements {
  margin-bottom: 2rem;
  i {
    margin-right: 1.5rem;
  }
  a {
    display: block;
    margin-bottom: 1rem;
  }
}

// Result page
.register-sign {
  dd,
  dt {
    padding-top: 1rem;
    padding-bottom: 0.5rem;
    margin-bottom: 0;
  }
  dt {
    font-weight: 500;
    color: $black;
  }
  .register-sign-fund-links {
    margin-top: 2.14rem;
  }
  .sign-fund-link {
    margin-bottom: 1.29rem;
    display: block;
  }
}

// Register ended
.register-ended {
  text-align: center;
  padding-top: 5.4rem;
  .image-wrapper {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    border-radius: 100%;
    width: 268px;
    background-color: $gray-100;
    margin-bottom: 2rem;
  }
  img {
    width: 100%;
    height: 100%;
  }
  .btn {
    margin-top: 3rem;
  }
}

.bankid {
  display: flex;
  height: 100%;
  flex: 1;
  flex-direction: column;
  .bankid-img {
    max-width: 108px;
    display: block;
    margin: 1rem auto 1.8rem;
  }
  h1,
  p,
  .bankid-complete-spinner,
  a {
    text-align: center;
  }
  .bankid-btn {
    min-width: 48%;
    // Have to override the styling in buttons.scss which overrides <Button /> classes
    &.btn-default:first-child:not(.btn-primary):not(.btn-danger):not(.btn-secondary) {
      @extend .btn-primary;
      color: $white;
    }
    @include media-breakpoint-down(sm) {
      margin-bottom: 1rem;
    }
  }
  .btn-wrapper {
    width: 100%;
    margin-top: 4rem;
    @include media-breakpoint-down(sm) {
      margin-top: 1.5rem;
    }
  }
}

// Mobile styles
@include media-breakpoint-down(sm) {
  .register header {
    background-color: $body-bg;
    img {
      max-width: 90px;
      max-height: 29px;
    }
  }
  .register-content,
  .register header {
    padding: 1.42rem;
  }
  .register-content {
    margin-top: 0;
    margin-bottom: 0;
  }
  .register-start-btn-wrapper {
    position: fixed;
    bottom: 5px;
    z-index: 11;
    left: 1%;
    width: 100%;
    // Fix to solve issue with iPhone home indicator placing itself on top of fixed button https://benfrain.com/css-environment-variables-iphonex/
    /*Default padding*/
    padding-bottom: 0;
    /*iOS < 11.2*/
    padding-bottom: constant(safe-area-inset-bottom);
    /*iOS 11.2 >*/
    padding-bottom: env(safe-area-inset-bottom);
  }
  // Put this button in register-bottom that is fixed.
  .register-start-btn {
    width: 98% !important; // Override bootstrap class with important.
  }
  .register-shares-tab-content {
    margin-bottom: 6rem;
  }
}

.register-next-btn-wrapper,
.register-btn-wrapper {
  @include media-breakpoint-down(sm) {
    background-color: $white;
    position: fixed;
    width: 100%;
    left: 0;
    bottom: 80px;
    box-shadow: 0 -7px 20px -12px rgba(0, 0, 0, 0.1);
    padding: 0.71rem 1.42rem;
  }
}

// Some flow uses the custom components in a logged in state and we need to override some things.
.register-compenents-logged-in {
  position: relative;
  .register-compenents-logged-in-content {
    width: 100%;
    .dynamic-form,
    .error-message,
    .button-row,
    .register-start,
    .register-shares-wrapper,
    .register-sign,
    .register-bank-id {
      @include make-col-offset(3);
      @include make-col(6);
      @include media-breakpoint-down(sm) {
        @include make-col-offset(0);
        @include make-col(12);
      }
    }
  }
  .register-shares-button-wrapper {
    display: flex;
    justify-content: space-between;
  }
  h3 {
    text-align: center;
  }
  .register-bottom,
  .progress,
  .register-filler {
    position: static;
  }
  .userflow-prev-btn {
    position: absolute;
  }
  .progress {
    margin-top: 3rem;
  }
  .register-shares {
    .register-next-btn-wrapper {
      width: 100%;
    }
  }
}
