.table-accordion {
  width: 100%;
  color: $table-color;
  margin: 0;
  padding: 0;
  .table-accordion-header-inner,
  .table-accordion-row-inner {
    display: flex;
    flex-direction: row;
    align-items: center;
  }
}

.table-accordion-header {
  margin-bottom: 1rem;
}

.table-accordion-header-inner {
  // Transparent border just to give it the same with as the body.
  border: 1px solid transparent;
}

.table-accordion-th {
  border-top: none;
  font-weight: normal;
  border-bottom: none;
  font-size: 0.75rem;
  color: $gray-800;
  letter-spacing: unset;
  i {
    margin-left: 3px;
  }
  &:first-child {
    padding-left: 20px;
  }
}

.table-accordion-row {
  background-color: #fff;
  margin-bottom: 5px;
  &.table-accordion-row-hover {
    cursor: pointer;
  }
  &:nth-of-type(odd) {
    background-color: $gray-100;
  }
}

.table-accordion-td {
  padding: 0 5px;
  font-size: 0.875rem;
  &:first-child {
    padding-left: 20px;
  }
  // Last child contains the toggle button to keep all columns the same size
  &:last-child {
    padding-right: 6px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }
}

.table-accordion-row-inner {
  padding: 13px 0;
}

// @if $table-striped {
//   .table-accordion-row:nth-of-type(#{$table-striped-order}) {
//     background-color: $table-accent-bg;
//   }
// }

.table-accordion-content {
  padding: 13px 6px 13px 20px;
}

.table-accordion-content {
  width: 100%;
  display: none;
  &.open {
    display: block;
  }
}

.table-accordion-toggle {
  background-color: transparent;
  border: none;
  color: $brand-primary;
  &:focus {
    outline: none;
  }
}

.table-accordion-in-row-toggle {
  background: transparent;

  text-align: center;
  display: inline-block;
  border: none;
  &.open {
    padding-left: 0.5em;
  }
  &:focus {
    outline: none;
  }
}
