.floating-message-container {
  position: fixed;
  bottom: $spacer / 3;
  right: 0;
  z-index: 5000;
  width: 30rem;

  .alert {
    opacity: 1;
    background-color: $white;
    margin: $spacer / 3 $spacer / 2 0 0;
    padding: 1rem 1.42rem;
    position: relative;
    border-radius: 5px;

    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;

    h4 {
      font-size: 1rem;
      font-weight: bold;
    }

    h4,
    p {
      margin-bottom: 0;
    }

    span i {
      // Font awesome icon
      font-size: 2.1em;
      margin-right: 1.42rem;
    }

    &.alert-success i {
      color: $success;
    }
    &.alert-danger i {
      color: $danger;
    }
    &.alert-warning i {
      color: $warning;
    }

    .close {
      @extend .btn-text;
      opacity: 1;
      font-size: 2rem;
      color: currentColor;
      text-shadow: none;
      font-weight: normal;
      position: absolute;
      top: $spacer / 3;
      right: $spacer / 3;
    }
  }
}
