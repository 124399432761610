.site {
  position: relative;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.main {
  flex-grow: 1;
  padding-left: 0;
  padding-right: 0;
}

.unauthenticated {
  max-width: 750px;
  text-align: center;
  padding-top: 8rem;
  padding-bottom: 8rem;
  box-shadow: 0 35px 30px -35px rgba(95, 3, 201, 0.1);
  img,
  .fal,
  .fad {
    margin-bottom: 2rem;
  }
  .fal,
  .fad {
    font-size: 80px;
    color: $brand-primary;
  }
  a {
    margin-top: 2rem;
  }
  &.error-boundary {
    padding-top: 5rem;
    max-width: none;
  }
}

.page-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 3rem;
  h1,
  h2 {
    margin-bottom: 0;
  }
}

.content {
  padding: 2.5rem;
  background-color: $white;
  margin-top: 5.625rem;
  margin-bottom: 0;
  @include media-breakpoint-down(md) {
    padding: 1rem;
  }
}

img {
  max-width: 100%;
}

// $content-padding: 2.86rem; // 40px
// $content-padding-md: 1rem;
// $content-margin-top: 6.42rem; // 90px

.white-wrapper {
  background-color: $white;
  padding: 2.5rem;
}

.breadcrumb-item {
  & + .breadcrumb-item {
    &::before {
      content: '\f101';
      font-family: 'Font Awesome 5 Pro';
      font-weight: 900;
    }
    &.active {
      color: $body-color;
    }
  }
}

.fund-advisor .content,
.content.no-content {
  background-color: transparent;
  padding-left: 0;
  padding-right: 0;
}

// .accordion-header-component {
//   display: flex;
//   flex: 1;
//   justify-content: space-between;
//   align-items: center;
//   margin-right: 1.78rem;
//   font-size: 16px;
// }

// .small-container {
//   max-width: 846px;
// }

// .small-gutter {
//   $small-gutter: 3px;
//   .row {
//     margin-right: -$small-gutter;
//     margin-left: -$small-gutter;
//   }
//   .col-md-4 {
//     padding-left: $small-gutter;
//     padding-right: $small-gutter;
//   }
// }

// .page-header {
//   padding: 0 0 1em 0;
//   display: flex;
//   flex-direction: row;
//   justify-content: space-between;
//   align-items: center;

//   // Used when multiple buttons are aligned to the right to give them spacing.
//   .btn-row {
//     margin: 0;
//   }
// }
