.karl-announcement-blocks {
  .card {
    border: 1px solid $gray-300;
    background-color: $white;
  }
  .karl-announcement-block-date {
    margin-bottom: 5px;
  }
}
.karl-announcements-show-more-btn {
  text-align: center;
}

.karl-announcement {
  .card {
    margin-top: 0;
    background-color: $white;
    border: 1px solid $gray-300;
  }
  .main-card-body {
    padding-top: 3.57rem;
  }
}
