// <Button /> component always adds the default class so we need to use :not.

.btn-default:not(.btn-primary):not(.btn-danger):not(.btn-secondary) {
  border-color: $brand-primary;
  color: $brand-primary;
}

.btn-wide {
  padding-left: 5.4rem;
  padding-right: 5.4rem;
}

.btn-link-style {
  border: none;
  background-color: transparent;
  color: $brand-quinary;
  &:focus {
    outline: none;
  }
}

.btn {
  text-transform: uppercase;
  &:disabled {
    background-color: $gray-300;
    border-color: $gray-300;
  }
  &.btn-primary {
    &:hover {
      background-color: $brand-quinary;
    }
  }
  &.btn-default.active {
    @extend .btn-primary;
  }
  @include icon-positioning('btn', 10px);
}

.link-styled {
  @extend .btn;
  background: transparent;
  border: none;
  color: $link-color;
  &:hover {
    color: $link-hover-color;
  }

  &.reset-field {
    color: $brand-danger;
    &:focus {
      box-shadow: none;
    }
  }
}

.btn-text {
  border: none;
  background-color: transparent;
  padding: 0;
}

.btn-sm {
  text-transform: none;
  font-weight: $btn-font-weight-sm;
  line-height: $btn-line-height-sm;
}
