.nav-tabs,
.nav-pills {
  border-bottom: none;
  margin-bottom: 2.5rem;

  .nav-link {
    font-size: 0.85rem;
    border: 1px solid $gray-300;
    padding: 0.5rem 2.5rem;
    color: $body-color;
    &.active {
      color: $brand-primary;
      font-weight: 600;
      border-color: $gray-300 $gray-300 $brand-tertiary;
    }
  }
}
