body {
  -webkit-font-smoothing: antialiased;
  line-height: 1.625;
}

/**
* FONTS
*/
ul {
  list-style: none;
  padding: 0;
}

h1 {
  margin-bottom: 2rem;
}

h3 {
  font-weight: 500;
}

p,
dd,
dt,
button {
  font-size: 0.875rem; // 14px
  line-height: 1.86; // 26px
}

p {
  font-size: 1rem;
}

a {
  font-weight: 600;
}

// Use with p
@mixin chapter-heading() {
  font-size: 1.14rem; // 16px;
  font-weight: 200;
  text-align: center;
  margin-bottom: 1.43rem; // 20px
}
.chapter-heading {
  @include chapter-heading();
}

.pre-line {
  white-space: pre-line;
}

// .small-heading {
//   font-size: 1.14rem;
//   font-weight: 600;
//   margin-bottom: 1.42rem;
// }

// .base-font-weight {
//   font-weight: $font-weight-base;
// }

// .font-weight-500 {
//   font-weight: 500;
// }

// .large-paragraph {
//   font-size: 1.14rem; // 16px
// }

// .small-paragraph {
//   font-size: 0.86rem; // 12px
// }

// .light-italic {
//   font-weight: 300;
//   font-size: 16px;
//   font-style: italic;
// }

// .italic {
//   font-style: italic;
// }

// .no-content {
//   // Same as bold-h1
//   h1 {
//     font-weight: 700;
//     font-size: 1.71rem; // 24px
//   }
// }

/**
* LISTS
*/

// @include ol-custom-style($brand-primary, 2rem, 1.14rem);
// @include ul-custom-style($brand-primary, 5px, -3px 22px 0 2px, 1.14rem);

// Circle with color before list item (used in onboarding flow)
.color-list {
  list-style: none;
  display: flex;
  flex-direction: row;
  justify-content: center;
  li {
    display: flex;
    align-items: baseline;
    list-style: none;
    font-size: 16px;
    color: $black;
    font-weight: 600;
    margin-right: 1rem;
    &:before {
      content: '';
      border-radius: 100%;
      width: 13px;
      height: 13px;
      display: inline-block;
      background-color: $brand-primary;
      margin-top: 3px;
      margin-right: 0.5rem;
    }
    &.quaternary:before {
      background-color: $brand-quaternary;
    }
    &:last-child {
      margin-right: 0;
    }
  }
}

@mixin flex-list-base() {
  list-style: none;
  display: inline-flex;
  align-items: flex-start;
  width: 50%;
  flex-direction: row;
  flex-wrap: wrap;
}

// List with round purple circle and check icon as list item.
.check-list {
  li {
    @include flex-list-base();
    position: relative;
    padding-left: 3.9rem;
    padding-right: 1rem;
    margin-bottom: 1rem;
    font-size: 16px;

    &:before {
      content: '\f00c';
      position: absolute;
      left: 0;
      font-family: 'Font Awesome 5 Pro';
      color: $white;
      display: inline-block;
      width: 25px;
      height: 25px;
      border-radius: 100%;
      background-color: $brand-primary;
      display: flex;
      align-items: center;
      justify-content: center;
      font-weight: 500;
      font-size: 12px;
    }
  }
  @include media-breakpoint-down(sm) {
    li {
      width: 100%;
    }
  }
}

// Line between two spans.
.split-list {
  margin-bottom: 0;
  li {
    list-style: none;
    display: inline-flex;
    width: 100%;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: flex-end;
    font-size: 16px;
    line-height: 16px;
    margin-bottom: 1.6rem;
  }
  .split-item {
    display: inline-block;
    z-index: 10;
  }
  .split-item-filler {
    flex: 1;
  }
  .line {
    border-bottom: 1px solid $gray-300;
    margin: -1px 5px 0;
  }
}

.icon-bg-list {
  li {
    position: relative;
    padding: 1rem;
    align-items: center;
  }
  .fal,
  .far {
    color: $brand-primary;
    min-width: 30px;
  }
  .bg-element {
    z-index: 9;
    display: block;
    height: 100%;
    min-width: 41px;
    position: absolute;
    left: 0;
    background-color: $gray-200;
    border-radius: 20px;
  }
}

.text-quaternary {
  color: $brand-quaternary;
}

.description-list-item {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  height: 3.58rem;
  padding: 1rem 1.43rem 0 1.43rem;
  margin-bottom: 0;
  &:nth-child(odd) {
    background: $body-bg;
  }
  dt {
    font-weight: 300;
  }
  @include media-breakpoint-down(md) {
    height: auto;
  }
}

// Highlighted icon blocks on fund advice overview
.highlight-block {
  text-align: center;
  h3 {
    font-size: 0.875rem;
    margin-bottom: 0;
    text-transform: uppercase;
  }
}

.highlight-block-link:hover {
  text-decoration: none;
}

.icon-highlight {
  border-radius: 100%;
  width: 71px;
  height: 71px;
  box-shadow: 0 0 20px 0 rgba(95, 3, 201, 0.1);
  display: inline-flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 1.25rem;
  i {
    font-size: 1.86rem;
    &:before {
      color: $brand-primary;
    }
    &:after {
      color: $brand-secondary;
    }
  }
  .indecap-rating {
    height: 20px;
    width: 28px;
    background-repeat: no-repeat;
    background-size: contain;
  }
  &.small {
    width: 35px;
    height: 35px;
    margin-bottom: 0;
    i {
      font-size: 1.1rem;
    }
  }
}

.indecap-rating {
  display: inline-block;
  background-image: url('assets/images/indecap-rating-filled.svg');
  background-size: contain;
  height: 11px;
  width: 16px;
  margin-right: 2px;
  &.indecap-rating-unfilled {
    background-image: url('assets/images/indecap-rating-unfilled.svg');
  }
  &.large {
    width: 52px;
    height: 37px;
    margin-right: 15px;
  }
}

.indecap-rating-container {
  width: 90px;
  &.large {
    width: 335px;
    margin: auto;
  }
}

.mstar-sustainability-rating {
  display: inline-block;
  height: 15px;
  width: 15px;
  margin-right: 4px;
  background-image: url('assets/images/morningstar_sus_inactive.svg');
  background-size: contain;
  &.mstar-sustainability-rating-unfilled {
    background-image: url('assets/images/morningstar_sus_active.svg');
  }
}

.pointer {
  cursor: pointer;
}

// Icons
.large-icon-with-circle-bg {
  width: 78px;
  height: 78px;
  background-color: $brand-primary;
  border-radius: 100%;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 40px;
  i {
    font-size: 28px;
    color: $white;
  }
}
