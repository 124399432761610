.auth-login {
  display: flex;
  flex-direction: row;
  height: 100vh; // This makes the container content overlow for small screens. Not sure to handle it.

  .nav-tabs {
    margin-bottom: 1rem;
  }
  .nav-item {
    flex: 1;
    text-align: center;
  }
  .iris-register-image {
    display: block;
    margin: 0 auto 1.5rem;
  }
}

.auth-login-section {
  flex: 1;
}

.auth-login-section-left {
  background-color: $gray-100;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding-top: 3.36rem;
  @include media-breakpoint-down(md) {
    flex: 1;
  }
  .card {
    background-color: $white;
  }
}

.auth-logo-wrapper {
  width: 460px;
  img {
    max-width: 135px;
  }
}

.auth-wrapper {
  max-width: 460px;
  width: 100%;
  min-height: 460px; // Add min height so that the tabs wont jump when you switch between them.
  margin: 2em 0;
  @include media-breakpoint-down(sm) {
    .tab-content .card-body {
      padding: 1rem;
    }
  }

  .custom-select {
    background: url('./assets/images/angle-up-down.svg') no-repeat right 1.8rem center/16px 16px $white;
    border-radius: 0;
    font-size: 1rem;
  }
}

.auth-login-section-right {
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: $gray-200;
  @include media-breakpoint-down(md) {
    display: none;
  }
  .register-title {
    font-size: 1.5rem;
    font-weight: 700;
    color: $brand-primary;
    margin-left: 35px;
    text-align: left;
  }
}
.auth-login-section-right-content {
  max-width: 490px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}

.iris-text-bubble {
  position: relative;
  background: $white;
  max-width: 360px;
  border-radius: 1rem;
  padding: 2rem 2rem 2rem;
  align-self: flex-end;
  margin-right: 35px;
  &:before {
    content: '';
    position: absolute;
    width: 0;
    height: 0;
    top: -8px;
    right: 30px;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-bottom: 10px solid $white;
  }
  p {
    margin-bottom: 0;
  }
}

// Bank ID
.mobile-bank-id-wrapper {
  position: relative;
}
.mobile-bank-id-img {
  position: absolute;
  top: 20px;
  right: 20px;
  background-image: url('assets/images/bankid_high_rgb.png');
  background-size: cover;
  width: 27px;
  height: 27px;
}

// Mobile styles (SM)
@include media-breakpoint-down(sm) {
  .auth-logo-wrapper,
  .auth-wrapper {
    width: 100%;
    padding-left: 1.42rem;
    padding-right: 1.42rem;
  }
}

.auth-qr-code {
  width: 140px;
  height: 140px;
  margin: 0 auto;
}

.bankid-iframe {
  opacity: 0;
}
