$mc-dropdown-item-padding-y: 0.5rem;
$mc-dropdown-item-padding-x: $dropdown-item-padding-x;

.multiselect-checkboxes,
.gizmo-multiselect-checkboxes {
  $mc-tag-bg: $white !default; // Background color of tags.
  $mc-tag-color: $input-color !default; // text color of tags.
  $mc-tag-height-scale: 0.6 !default; // The % of multuselect tags height compared to input field;
  $mc-dropdown-item-border-radius: $input-border-radius !default;
  // TODO:  $mc-input-height: $search-field-input-height;
  $mc-input-height: $input-height;

  position: relative;
  color: $input-color;

  .mc-visible,
  .gizmo-mc-visible {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    height: $mc-input-height;
    background-color: $input-bg;
    border-radius: $mc-dropdown-item-border-radius;
    border: $input-border-width solid $input-border-color;

    &:focus {
      box-shadow: $input-focus-box-shadow;
      outline: none;
    }
  }

  .mc-value-field,
  .gizmo-mc-value-field {
    white-space: nowrap;
    width: 100%;
    padding-left: $input-padding-x;
    &.mc-placeholder,
    &.gizmo-mc-placeholder {
      color: $search-fields-placeholder-color;
    }
  }

  .mc-toggle-btn,
  .gizmo-mc-toggle-btn {
    background-color: transparent;
    border: none;
    outline: none;
    width: 2 * $input-padding-x;
    color: $brand-secondary;
  }

  // Override default position for indeterminate checkbox used in Table.
  .checkbox input[type='checkbox'].indeterminate:not(:checked):before,
  .checkbox input[type='checkbox'].indeterminate:checked:before {
    top: 2px;
  }

  .multiselectcheckboxes-group,
  .gizmo-multiselectcheckboxes-group {
    display: block;
    font-weight: bold;
    color: $dropdown-header-color;
    padding: $dropdown-header-padding;
  }

  .mc-dropdown,
  .gizmo-mc-dropdown {
    background-clip: padding-box;
    display: none;
    position: absolute;
    top: 100%;
    border-radius: $dropdown-border-radius;
    border: $dropdown-border-width solid $dropdown-border-color;
    box-shadow: $dropdown-box-shadow;
    background-color: $white;
    margin-top: $dropdown-spacer;
    width: 100%;
    max-height: 242px;
    overflow-y: scroll;
    z-index: 1001;
    padding: 0 $dropdown-padding-x;
    &.mc-open,
    &.gizmo-mc-open {
      display: block;
    }
  }

  .mc-items,
  .gizmo-mc-items {
    margin-top: $dropdown-padding-x;
    padding: $dropdown-padding-y 0;

    &:not(:first-child) {
      border-top: 1px solid $dropdown-divider-bg;
    }
  }

  .mc-item,
  .gizmo-mc-item {
    padding: $mc-dropdown-item-padding-y $mc-dropdown-item-padding-x;
    border-radius: $input-border-radius;
    label {
      width: 100%;
    }
  }

  .mc-taglist,
  .gizmo-mc-taglist {
    margin: 0;
    padding: 0;
    list-style: none;
    display: inline;
    outline: none;
  }
  .mc-tag,
  .gizmo-mc-tag {
    $tag-height: calc(#{$mc-input-height} * #{$mc-tag-height-scale});
    $tag-gutter: calc(#{$mc-input-height} * (1 - #{$mc-tag-height-scale}) / 2);

    display: inline-block;
    margin: $tag-gutter;
    line-height: $tag-height;
    color: $mc-tag-color;
    padding: 0 0.75em 0 0.75em;
    &:first-child {
      margin-left: 0;
      padding-left: 0;
    }
    vertical-align: middle;
    border-radius: $mc-dropdown-item-border-radius;
    background-color: $mc-tag-bg;
    max-width: 100%;
  }
  .mc-tag-btn,
  .gizmo-mc-tag-btn {
    color: $mc-tag-color;
    background-color: transparent;
    border: none;
    &:focus {
      outline: none;
    }
  }
}

.search-field {
  .multiselect-checkboxes,
  .gizmo-multiselect-checkboxes {
    $mc-input-height: $search-field-input-height;
    .mc-visible,
    .gizmo-mc-visible {
      height: $mc-input-height;
    }
  }
}

.gizmo-mc-search-input-wrapper {
  padding: 0.25rem;
  position: relative;
}

.gizmo-mc-search-input {
  height: $input-height-slim;
  min-height: $input-height-slim;
}

.gizmo-mc-search-input-icon {
  position: absolute;
  right: 1rem;
  top: 1.25rem;
  color: $brand-primary;
}
