// @include modal-close-btn-fa("\f00d", 900, 20px, $brand-primary, 'Font Awesome 5 Pro');

.modal-header {
  .modal-title {
    font-size: 1rem;
  }
}

.modal-header,
.modal-footer {
  background-color: $body-bg;
}

.modal-header {
  .btn-close {
    background: none;
    opacity: 1;
    display: flex;
    align-items: center;
    opacity: 0.5;
    &:before {
      content: '\f00d';
      font-family: 'Font Awesome 5 Pro';
      font-weight: 900;
      font-size: 1.25rem;
      color: $brand-primary;
    }
    &:focus {
      box-shadow: none;
    }
  }
}

.modal-footer {
  padding: $modal-header-padding-y $modal-header-padding-x;
}

// No header or footer highligting
.simple-modal {
  .simple-modal-header {
    text-align: right;
    margin-bottom: 2.5rem;
  }
  .simple-modal-footer {
    margin-top: 2.5rem;
    button {
      width: 100%;
    }
  }
}

// Confirmation Modal
.confirmationmodal {
  text-align: center;
  .questionmark,
  .completed {
    margin: 0 0 1.25rem;
    i {
      font-size: 4rem;
    }
  }
  .questionmark i {
    color: $brand-primary;
  }
  .completed i {
    color: $brand-success;
  }
  h3 {
    // font-size: $confirmation-modal-body-title-font-size;
    // font-weight: $confirmation-modal-body-title-font-weight;
    // margin-bottom: $confirmation-modal-body-title-margin-bottom;
  }
}
