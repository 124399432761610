@use 'react-widgets/scss/styles.scss' with (
  $input-color: $black,
  $input-border-color: $gray-300,
  $input-height: calc(#{$input-line-height}em + 2 * #{$input-padding-y} + 2 * #{$input-border-width}),
  $input-padding-x: $input-padding-x,
  $input-border-radius: 0,
  $input-focus-border-color: $brand-primary,
  $input-box-shadow: none,
  $input-placeholder-color: $search-fields-placeholder-color,
  $input-addon-width: 2.9rem,
  $widget-border-radius: 0,
  $widget-font-size: 1rem,
  $widget-border-color: $gray-300,
  $widget-hover-bg: $gray-100,
  $widget-focus-box-shadow: none,
  $picker-btn-padding-right: 1.5rem // $picker-btn-padding-left: 1rem
);

// Change Dropdown icon
.rw-picker-caret,
.rw-input-addon {
  &.rw-picker-btn {
    border-left: none;
  }
  svg {
    display: none;
  }
  &:before {
    content: '\f078';
    font-family: 'Font Awesome 5 Pro';
    font-weight: 300;
    font-size: 1rem;
    color: $brand-secondary;
  }
}

// Change Datepicker icon
.rw-date-picker .rw-picker-btn {
  border-left: none;
  padding-right: 0;
  svg {
    display: none;
  }
  &:before {
    content: '\f073';
    font-family: 'Font Awesome 5 Pro';
    font-weight: 300;
    font-size: 1rem;
    color: $brand-secondary;
  }
}

// List styling
.rw-list {
  padding-top: 0;
  padding-bottom: 0;
}

.rw-list-option {
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
}

.rw-list-option {
  border-left: 3px solid transparent;
  &.rw-state-selected,
  &:hover {
    background-color: $gray-100;
    border-color: transparent;
    border-left: 3px solid $brand-primary;
    color: $black;
    font-weight: 500;
  }
}

.rw-select-bordered {
  padding-right: 1rem;
}

// Datepicker styling
.rw-cell {
  &.rw-state-selected {
    background-color: $brand-tertiary;
    color: $brand-primary;
  }
}

.rw-calendar-btn,
.rw-head-cell {
  color: $brand-primary;
}

.rw-sm {
  max-width: 150px;
  .rw-widget-picker {
    min-height: calc($btn-line-height-sm + 2 * #{$btn-padding-y-sm} + 2 * #{$input-border-width});
  }
  .rw-input {
    padding-left: $btn-padding-x-sm;
    padding-right: 0;
  }
}

.rw-month-picker {
  .rw-input {
    text-transform: capitalize; // defaults to lowercase
  }
}

/*
      $input-height: calc(#{$input-line-height}em + 2 * #{$input-padding-y} + 2 * #{$input-border-width}),
      $input-padding-x: $input-padding-x,
      $input-border-radius: 0,
      $input-addon-width: 3rem,
      $widget-hover-bg: $cream,
      $input-focus-border-color: $purple,
      $widget-focus-box-shadow: none,
      $widget-border-radius: 0,
      */
