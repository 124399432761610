.rc-slider {
  margin-top: 1.25rem;
  margin-bottom: 1.25rem;
}
.rc-slider-rail {
  border-radius: 0;
  height: 5px;
  background-color: $gray-400;
}
.rc-slider-track {
  background-color: $brand-primary;
}
.rc-slider-handle {
  width: 25px;
  height: 25px;
  opacity: 1;
  margin-top: -10px;
  border: 1px solid $gray-300;
  background: $brand-primary;
  &:hover,
  &:focus {
    border-color: $gray-300;
    width: 45px;
    height: 45px;
    outline: 1px solid $gray-300;
    margin-top: -20px;
  }
  &:hover {
    border: 10px solid $gray-200;
  }
  &:focus,
  &.rc-slider-handle-dragging.rc-slider-handle-dragging.rc-slider-handle-dragging {
    border: 10px solid $brand-primary;
    box-shadow: none;
    border-color: $brand-primary;
  }
}

.rangeslider-caption .rangeslider-number-caption {
  text-align: center;
  font-size: 1.375rem;
  font-weight: 700;
}

.rangeslider-caption .col-form-label {
  text-align: center;
  font-size: 1rem;
  font-weight: $font-weight-bold;
}

.rangeslider-caption-flex,
.rangeslider-caption-remaining-value-headers,
.rangeslider-caption-remaining-value-values {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 0.5rem;
  p {
    margin-bottom: 0;
  }
}

.rangeslider-caption-flex {
  .rangeslider-value {
    font-size: 1rem;
    font-weight: 700;
  }
  .rangeslider-unit {
    font-weight: 300;
    font-size: 10px;
  }
}

.rangeslider-caption-remaining-value-values {
  p {
    font-size: 1rem;
    font-weight: 700;
  }
}
