.form-group {
  margin-bottom: 2rem;
  &.row {
    display: block;
  }

  @include media-breakpoint-up(sm) {
    .col-sm-4,
    .col-sm-8 {
      width: 100%;
      max-width: 100%;
    }
    .offset-sm-4 {
      margin-left: 0;
    }
  }
}

.col-form-label {
  padding-top: 0.2rem;
  padding-bottom: 0.2rem;
}

textarea.form-control,
.slim-form textarea.form-control {
  min-height: 180px;
}

:not(.search-field-wrapper) > .btn-row {
  margin-top: 2rem;
  display: flex;
  justify-content: flex-end;
  flex-wrap: wrap;
  align-items: flex-start;
  .btn {
    margin-left: 1rem;
  }
}

// Typeahead
.typeahead {
  position: relative;
  .fa-spinner {
    position: absolute;
    right: 1rem;
    top: 35%;
  }
}

// Hide input number arrows
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
input[type='number'] {
  -moz-appearance: textfield;
}

@mixin slim-form() {
  // input,
  .rw-widget-picker,
  .form-control {
    @extend .form-control-sm;
    // pa
    height: $input-height-slim;
    min-height: $input-height-slim;
  }
  .rw-widget-picker {
    padding-left: 0;
    padding-right: 0;
  }
  .rw-list-option {
    padding: $input-btn-padding-y-sm $input-btn-padding-x-sm;
  }
}

.slim-form {
  @include slim-form;
}

.slim-input {
  height: $input-height-slim;
  padding-top: $input-btn-padding-y-sm;
  padding-bottom: $input-btn-padding-y-sm;
}

.dynamic-form {
  .col-form-label {
    margin-bottom: 0.8rem;
  }

  .field-input-wrapper {
    .radio:last-child,
    .checkbox:last-child {
      border-bottom: 1px solid $gray-300;
    }
  }
}

.dropzone {
  padding: 76px 122px;
  border: 2px dashed $brand-tertiary;
  text-align: center;
  i {
    padding-right: 15px;
    color: $brand-secondary;
    font-size: 1.56rem;
  }
}

// TODO: https://w3bits.com/css-floating-labels/

// Repeat form section buttons
.add-repeat-section-btn,
.delete-repeat-section-btn {
  background-color: transparent;
  border: none;
  padding: 0;
  color: $brand-primary;
  text-transform: none;
  font-size: 1rem;
  font-weight: 500;
  margin-bottom: 2rem;
  &:hover {
    background-color: transparent;
  }
  &:focus {
    box-shadow: none;
  }
  &:after {
    content: '\f067';
    font-family: 'Font Awesome 5 Pro';
    font-weight: 900;
    margin-left: 1rem;
  }
}

.delete-repeat-section-btn {
  position: absolute;
  right: 0;
  z-index: 11;
  color: $brand-danger;
  &:hover {
    color: $brand-danger;
  }
  &:after {
    content: '\f00d';
  }
}

form {
  // Field.jsx select components needs to be 100% since they are not wrapped in the row-class as Field2 is.
  .form-group.select {
    width: 100%;
  }
}

// make sure flow readonly list indentation is correct
.readonly-multiselect-form-answer li {
  margin: 0 1rem;
}

// Flow buttons (prev,next) fill up entire
.flow-buttons-space-between {
  .button-row {
    display: flex;
    justify-content: space-between;
  }
  .userflow-prev-btn,
  .userflow-next-btn {
    width: 48%;
  }
}

.file-upload-file-list {
  td {
    vertical-align: middle;
  }
  .icon-btn {
    @extend .btn-sm;
  }
}

.error-message {
  font-size: 0.875rem;
  font-weight: 500;
  color: $brand-danger;
}

.form-container {
  h4 {
    font-weight: 600;
  }
}
