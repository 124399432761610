.bankid-mobile {
  margin-bottom: 1rem;
}

.bankid-qrcode-wrapper {
  text-align: center;
}

.gizmo-bankid-qr-code {
  svg {
    max-width: 100%;
    margin: 0 auto;
    width: 200px;
    height: 200px;
  }
  margin-bottom: 1rem;
}

.bankid {
  .component-loader {
    text-align: center;
  }
}
