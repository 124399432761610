.header {
  background-color: $white;
  border-bottom: 3px solid $brand-primary;
  @include media-breakpoint-down(lg) {
    padding: 0 $grid-gutter-width / 2;
    top: 0;
    position: fixed;
    overflow-x: hidden;
    z-index: 100;
    width: 100%;
    padding-top: 1.5rem;
    padding-bottom: 1.25rem;
    &.show-mobile-menu {
      bottom: 0;
      height: 100vh;
    }
  }
}

.navbar-brand {
  width: 135px;
}

.navbar {
  .nav-link {
    transition: all 0.1s ease-in;
    padding-top: 2rem;
    padding-bottom: 1.79rem;
    color: $body-color;
    @include media-breakpoint-down(lg) {
      padding-top: 1rem;
      padding-bottom: 1rem;
    }
  }
  .nav-link {
    &:not(.dropdown-toggle) {
      border-bottom: 5px solid transparent;
      &:hover,
      &.active {
        border-color: $brand-tertiary;
      }
      @include media-breakpoint-down(lg) {
        border-bottom: none;
      }
    }
    &.active {
      font-weight: 600;
    }
  }
  .nav-item {
    white-space: nowrap;
  }

  .dropdown-toggle {
    @include media-breakpoint-down(lg) {
      display: none;
    }
  }

  .dropdown-toggle:after {
    content: '\f107';
    display: inline-block;
    font-family: 'Font Awesome 5 Pro';
    font-weight: unset;
    border: none;
    vertical-align: inherit;
  }

  .dropdown {
    border-left: 1px solid $gray-300;
    @include media-breakpoint-down(lg) {
      border-left: none;
    }
  }

  .dropdown-menu {
    margin: 1rem 0 0;
    min-width: 100%;
    top: 100%;
    @include media-breakpoint-down(lg) {
      display: block;
      margin: 0;
      border: none;
    }
  }
  .dropdown-item {
    border-left: 5px solid transparent;
    color: $body-color;
    &:hover {
      border-color: $brand-primary;
    }
    @include media-breakpoint-down(lg) {
      border-left: none;
      padding: 1rem 0;
    }
  }
}

.navbar-nav {
  @include media-breakpoint-down(lg) {
    // display: none;
  }
}

.navbar-toggler {
  border: none;
  font-size: 1.25rem;
  @include media-breakpoint-up(lg) {
    display: none;
  }
}

// .header {
//   .dropdown-item {
//     border-left: 5px solid transparent;
//     &:hover {
//       border-color: $brand-primary;
//     }
//   }

//   .account-picker .account-username {
//     margin-bottom: 2px;
//   }
// }
// .nav-link {
//   font-weight: $font-weight-base;
//   &.active {
//     font-weight: 600;
//   }
// }

// // Mobile styles
// @include media-breakpoint-down(md) {
//   .header {
//     overflow-y: auto;
//     padding: 1.86rem 1.42rem 1.42rem;
//     position: fixed;
//     .user-menu .dropdown-item {
//       font-weight: 300;
//     }
//     .account-info-wrapper {
//       .add-isk-icon {
//         margin-right: 11px;
//       }
//     }
//   }
// }

// // Account Picker
// .custom-account-picker {
//   .add-isk-icon {
//     font-size: 1.14rem;
//     margin-right: 8px;
//   }
// }

// // Fund Advisor Header
// .fundadvisor-header {
//   .user-menu {
//     .dropdown-toggle:after {
//       margin-top: 0;
//     }
//   }
// }

.header-flow {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 2.5rem 3.3775rem 0;
  img {
    max-width: 130px;
    object-fit: contain;
  }
  a {
    color: $body-color;
  }
}
