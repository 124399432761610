/****************************************
 * Theme settings
 * Here we override the base theme:  "~@leanon/fuse2/lib/src/styles/base-theme.scss"
 * And bootstrap variables: "~bootstrap/scss/_variables.scss"
 *
 * If you want to modify the look:
 * 1. Open the base-theme and override the settings you want
 * 2. if it is not enough, modify bootstrap variables,
 * 3. If that is not enough, modify the css and add a new variable, and put it in base-theme.scss
 ***************************************/

$body-bg: #fbfafd;
$body-color: #000;
$white: #fff;
$brand-primary: #5f03c9;
$brand-secondary: #b884f4;
$brand-tertiary: #e5d5f7;
$brand-quaternary: #ffa026;
$brand-quinary: #863bdd;
$brand-success: #20b021;
$brand-danger: #b90505;
$brand-warning: #ffbd00;
$brand-light-primary: #b884f4;
$black: #000;
$paragraph-margin-bottom: 1.25rem;
$type-fk-color: $brand-primary;
$type-isk-color: $brand-secondary;
$sfdr-article-8-color: rgb(205, 225, 192);
$sfdr-article-9-color: rgb(41, 137, 54);

$theme-colors: (
  'primary': $brand-primary,
  'secondary': $brand-secondary,
  'success': $brand-success,
  'warning': $brand-warning,
  'danger': $brand-danger,
  'info': $brand-secondary,
  'tertiary': $brand-tertiary,
);

$gray-100: #fbfafd;
$gray-200: #f6f2fe;
$gray-300: #e3deec;
$gray-400: #dad4e5;
$gray-500: #d0cbda;
$gray-600: #cbc8d0;
$gray-700: #c2c2c2;
$gray-800: #7f7f7f;
$gray-900: #2f2f2f;

$grays: (
  '100': $gray-100,
  '200': $gray-200,
  '300': $gray-300,
  '400': $gray-400,
  '500': $gray-500,
  '600': $gray-600,
  '700': $gray-700,
  '800': $gray-800,
  '900': $gray-900,
);

$green1: #95f495;
$green2: #6de46d;
$green3: #6de46d;

$orange1: #ffefdb;

$yellow1: #fff9f1;
$yellow2: #e38205;

$container-max-widths: (
  // lg: 1050px,
  xl: 1240px
);

// Navbar
$navbar-padding-x: 0;
$navbar-padding-y: 0;
$navbar-nav-link-padding-x: 1rem;
$navbar-brand-margin-end: 1rem;

// Dropdown
$dropdown-padding-y: 0;
$dropdown-item-padding-y: 1.5rem;
$dropdown-item-padding-x: 2rem;
$dropdown-border-color: $gray-300;
$dropdown-border-width: 1px;
$dropdown-link-active-bg: $white;
$dropdown-link-active-color: $body-color;
$dropdown-divider-margin-y: 0;

// Layout
// $content-bg: $white;
// $content-padding: 2.86rem; // 40px
// $content-padding-md: 1rem;
// $content-margin-top: 6.42rem; // 90px
// $hr-border-color: $gray-300;
// $last-element-wrapper-margin: 0;
// $last-element-content-margin: 0;
// $last-elememt-pagination-margin-top: 1.43rem; // 20px
// $type-fk-color: $brand-primary;
// $type-isk-color: $brand-secondary;

// Fonts
$font-family-base: 'Montserrat';
$font-weight-base: 400;
// $font-size-base: 0.875rem;
$body-font-size: 16px;
$font-weight-bold: 500;
$line-height-base: 1.5;
$headings-margin-bottom: 1.25rem;
$h1-font-size: 1.375rem; // 22px
// $h1-margin-bottom: 2.15rem; // 30px
$h2-font-size: 1.25rem; // 20px;
// $h2-margin-bottom: 2.15rem; // 30px
$h3-font-size: 1rem; // 16px;
$h4-font-size: 0.875rem; // 14px;

// Links
$link-font-weight: 600;
$link-color: $brand-quinary;
$link-decoration: none;

// Header
// $header-bg: #fff;
// $header-color: #464646;
// $header-padding-top: 0;
// $header-hover-color: $header-color;
// $navbar-brand-padding: 0 0 0 0;
// $header-border-bottom: 3px solid $brand-primary;
// $header-logo-width: 135px;
// $navbar-brand-margin: 0 2.5rem 0 0;
// $header-dropdown-arrow-font-family: 'Font Awesome 5 Pro';
// $header-dropdown-arrow-font-weight: unset;
// $account-picker-dropdown-arrow-margin-top: 1.6rem;
// $account-picker-dropdown-arrow-margin-left: 0.4em;
// $mobile-account-info-wrapper-padding-top: 1rem;
// $mobile-account-info-wrapper-padding-bottom: 1rem;

// Menu
// $nav-link-padding-y: 0;
// $nav-link-border-bottom: 5px solid transparent;
// $nav-link-hover-border-color: $brand-tertiary;
// $navbar-nav-link-padding-x: 0;
// $navbar-nav-link-padding-top: 2em;
// $navbar-nav-link-padding-bottom: 1.79em;
// $navbar-nav-link-margin-x: 1em;
// $dropdown-nav-link-padding-bottom: 0;
// $dropdown-nav-link-padding-top: 0;
// $dropdown-link-active-bg: transparent;
// $navbar-right-dropdowns-border-left: 1px solid $gray-300;
// $navbar-toggler-font-size: 23px;

// $account-username-font-size: 0.85rem;
// $account-info-active-font-weight: 600;
// $account-info-font-weight: 600;
// $account-info-list-font-weight: 500;
// $account-username-font-weight: 600;
// $account-info-font-size: 0.786rem; // 11px

// // Account picker mobile (pick account in mobile menus)
// $account-picker-mobile-margin: 3rem 0 1rem;
// $account-picker-mobile-padding: 0 0 1.5rem;
// $account-picker-border-bottom: 1px solid $gray-300;
// $account-picker-drop-down-height: 3.57rem;

// Dropdowns
// $dropdown-spacer: 0;
// $dropdown-border-color: $gray-300;
// $dropdown-border-width: 1px;
// $dropdown-padding-y: 0;
// $dropdown-link-hover-bg: $gray-200;
// $dropdown-item-padding-y: 1.5em;
// $dropdown-item-padding-x: 2em;
// $dropdown-menu-margin: 5px 0 0;
// $dropdown-link-active-color: $gray-200;
// $dropdown-divider-margin-y: 0;

// Breadcrumbs
$breadcrumb-font-size: 0.75rem;
$breadcrumb-item-padding-x: 1.5rem;
$breadcrumb-padding-y: 1rem;
// $breadcrumb-padding-x: 0;
// $breadcrumb-item-padding: 1.43rem; // 20px;
// $breadcrumb-border-color: transparent;
// $breadcrumb-a-color: $brand-primary;
// $breadcrumb-position: absolute;
// $breadcrumb-active-color: $header-color;
// $breadcrumb-padding-x-sm: 2.86rem; // Same as content-padding

// Buttons
$btn-border-radius: 0;
$btn-padding-y: 1rem;
$btn-padding-x: 2rem;
$btn-padding-y-sm: 0.5rem;
$btn-padding-x-sm: 1rem;
$btn-font-size: 0.85rem;

$btn-font-size-sm: 0.875rem;
$btn-font-weight-sm: 600;
$btn-text-transform: uppercase;
$btn-default-border: 1px solid $gray-700;
$btn-focus-box-shadow: none;
$btn-active-box-shadow: none;
$btn-focus-width: 0;
$btn-border-radius-sm: 0;
$btn-line-height-sm: 22px; // 24 in figma but then we get a height of 42px

// Input
$input-height: 70px;
$input-border-color: $gray-300;
$input-bg: $white;
$input-focus-border-color: $brand-primary;
$input-font-size: 1rem;
$input-padding-y: 1.2rem;
$input-padding-x: 1.5rem;
$input-height-slim: 50px;
$input-btn-padding-y-sm: 1rem;
$input-btn-padding-x-sm: 1.5rem;
$input-btn-font-size-sm: 1rem;
$input-border-radius-sm: 0;
$input-box-shadow: none;
$input-border-radius: 0;
$input-line-height: 1.6275;
// $input-card-bg: #fff;
$input-focus-box-shadow: none;
$input-color: $black;
$input-placeholder-color: #999;
$form-feedback-valid-color: $gray-300;
$form-feedback-icon-valid: none;
$form-feedback-invalid-color: $gray-300;
$form-feedback-icon-invalid: none;
// $text-area-min-height: 180px;
// $form-group-margin-bottom: 2rem;

$form-label-font-weight: 500;

$progress-bar-bg: $brand-primary;

// Validation
// $help-block-error-background-color: $white;
// $help-block-error-color: $brand-danger;
// $help-block-margin-top: 1rem;
// $help-block-padding-y: 0;
// $help-block-padding-x: 0;
// $help-block-font-weight: 500;

// Tables
// $table-row-wrapper: false;
// $table-th-border-bottom: none;
// $table-th-font-size: 0.857rem; // 12px;
// $table-th-color: $gray-800;
// $table-border-color: transparent;
// $table-bg: $white;
// $table-accent-bg: $gray-100;
// $table-striped: true;
// $table-hover-background: $gray-200;
// $table-margin: 0 0 38px 0;
// $table-last-row-bg-color: $gray-300;

// $table-head-bg: $white;
// $table-head-color: $white;
// $table-bg: $white;
$table-hover-bg: $gray-200;
$table-accent-bg: $gray-100;
$table-striped-bg: $white;
$table-striped-order: even;
$table-border-width: 0;
$table-cell-padding-y: 1rem;
$table-cell-padding-x: 1.25rem;
$table-th-font-weight: 400;

// Pagination

$pagination-arrow-font-size: 1.6rem;
$pagination-arrow-padding-y: 0.5rem;
$pagination-padding-y: 0.71rem; // 10px
$pagination-padding-x: 1rem;
$pagination-active-bg: $brand-tertiary;
$pagination-active-color: $brand-primary;
$pagination-hover-bg: $pagination-active-bg;
$pagination-hover-color: $pagination-active-color;
$pagination-border-width: 0;
$pagination-color: $black;
$pagination-bg: $gray-200;
$pagination-disabled-color: $gray-300;
$pagination-disabled-bg: $gray-200;

//Submenu
// $nav-tabs-border-color:             $gray-300 !default;
// $nav-tabs-border-width:             $border-width !default;
// $nav-tabs-border-radius:            $border-radius !default;
// $nav-tabs-link-hover-border-color:  $gray-200 $gray-200 $nav-tabs-border-color !default;
// $nav-tabs-link-active-color:        $gray-700 !default;
// $nav-tabs-link-active-bg:           $body-bg !default;
// $nav-tabs-link-active-border-color: $gray-300 $gray-300 $nav-tabs-link-active-bg !default;

$nav-tabs-border-color: $gray-300;
$nav-tabs-border-radius: 0;
$nav-tabs-border-width: 1px;
$nav-tabs-link-hover-border-color: $gray-300;
$nav-tabs-link-active-color: $brand-primary;
$nav-tabs-link-active-bg: $brand-tertiary;

$nav-pills-border-radius: 0;
$nav-pills-link-active-color: $brand-primary;
$nav-pills-link-active-bg: $brand-tertiary;

// $nav-tabs-border-bottom: none;
// $nav-tabs-non-hover-border-color: $gray-300;

// $nav-tabs-padding-y: 0.5rem;
// $nav-tabs-padding-x: 3rem;
// $nav-tabs-padding-x-sm: 1.86rem;
// $nav-tabs-margin-bottom: 2.8rem;
// $nav-tabs-active-color: $brand-primary;

// $nav-pills-border-radius: 0;
// $nav-pills-link-active-color: $brand-primary;
// $nav-pills-link-active-bg: $brand-tertiary;
// $nav-pills-padding-x: 3rem;
// $nav-pills-padding-y: 0.5rem;
// $nav-pills-border: 1px solid $gray-300;
// $nav-pills-margin-bottom: 2.8rem;

// Cards
$card-bg: $gray-100;
// $card-margin-bottom: 1.64rem;
$card-border-width: 0;
$card-spacer-x: 2rem;
$card-spacer-y: 1.8725rem;
$card-cap-bg: transparent;

// React Widgets
// $input-height: 5rem;
// $input-bg-disabled: $gray-200;
// $input-padding-horizontal: $input-padding-x;
// $rw-popup-margin-top: 0.5rem;
// $rw-popup-border-top: 1px solid $gray-300;
// $state-border-select: white;
// $state-bg-hover: $white;
// $state-color-hover: $black;
// $list-color-select: $black;
// $list-border-select: $brand-primary;
// $state-border-hover: $brand-primary;
// $state-border-focus: $gray-300;
// $list-bg-hover: $gray-100;
// $list-bg-focus: $gray-100;
// $list-bg-select: $gray-100;
// $list-padding-vertical: 1.71rem;
// $list-padding-horizontal: 1.71rem;
// $icon-color: $brand-light-primary;
// $calendar-cell-border-hover: $brand-secondary;
// $calendar-cell-font-weight-focus-not-selected: 300;
// $calendar-cell-bg-select: $brand-tertiary;
// $calendar-cell-color-select: $brand-primary;
// $calendar-cell-bg-now: $brand-tertiary;
// $calendar-cell-color-now: $brand-primary;
// $calendar-cell-font-weight-now: 600;
// $rw-dropdown-arrow-padding: 0 2.5em 0 1em;
// $input-border: $gray-300;

// Search fields
$search-field-input-height: 3.125rem;
$search-fields-placeholder-color: $input-placeholder-color;
// $search-field-drop-down-option-padding: 0.78rem;
// $search-field-form-align: flex-end;
// $search-field-form-group-margin-bottom: 0;
// $search-field-btn-padding-y: 0.78rem;
// $transactions-last-date-button-margin-left-mobile: 0;
// $transactions-last-date-button-margin-top-mobile: 10px;

// System status bar
$system-status-bg: $brand-danger;

// Alerts
$alert-border-radius: 0;

// Divider bottom
$divider-bottom-border: 1px solid $gray-300;

// Footer
// $footer-bg: $gray-100;

// Modals
$modal-inner-padding: 2rem;
$modal-header-padding-y: 1.5rem;
$modal-header-padding-x: 2rem;
$modal-content-border-radius: 0;

// User account overview

// HR
$hr-margin-y: 2.85rem;

// General Error Message
$error-message-font-size: $body-font-size;
$error-message-font-weight: 500;
$error-message-color: $brand-danger;

// Tooltip
$tooltip-bg: $gray-100;
$tooltip-color: $black;
$tooltip-padding-y: 0.8rem;
$tooltip-padding-x: 1rem;
$tooltip-border: 1px solid $gray-300;
$tooltip-font-size: 0.75rem;

// Karl Announcements
// $karl-announcement-block-border-color: $gray-300;

// Table Accordion
$table-accordion-toggle-color: $brand-primary;

// ToggleField
$toggler-color-unchecked: $gray-300;
$toggler-color-checked: $brand-primary;
$toggler-color-transparent: $brand-tertiary;
$toggler-color-transparent-border: $brand-primary;
$toggler-border-color: $gray-300;
