/**
 * Font Awesome 5
 */
// @import '~@leanon/fuse2/lib/styles/legacy-mapping/fontawesome4.scss'; // adapt fontawesome 4 classes to -> 5

/**
 * Fontawesome 5 Pro
 */
@import 'fontawesome-pro-5.12.0-web/scss/fontawesome';
@import 'fontawesome-pro-5.12.0-web/scss/light';
@import 'fontawesome-pro-5.12.0-web/scss/regular';
@import 'fontawesome-pro-5.12.0-web/scss/solid';
@import 'fontawesome-pro-5.12.0-web/scss/duotone';

/**
  * Google Fonts
  */
@import url(https://fonts.googleapis.com/css?family=Montserrat:200,300,400,500,700);

/**
  * React rangeslider
  */
@import '~rc-slider/assets/index.css';
/**
  * LeanOn styling
  * Override LeanOn theme and bootstrap variables in theme-settings.
  * https://bootstrap-vue.js.org/docs/reference/theming/
  */
// @import '~@leanon/fuse2/lib/styles/base-theme';
@import 'theme-settings';

/**
  * Bootstrap variables
  */
@import '~bootstrap/scss/_functions.scss';
@import '~bootstrap/scss/_variables.scss';

/**
  * React Widgets
  * https://jquense.github.io/react-widgets/theming/
  */
// @import '~@leanon/fuse2/lib/styles/legacy-mapping/react-widget.scss'; // adapt react-widget from bootstrap3 -> bootstrap4
// @import '~react-widgets/lib/scss/react-widgets';

/**
  * Now include the rest of bootstrap when our variable overrides are loaded
  */
@import '~bootstrap/scss/bootstrap.scss';

/**
  * Include styling for styling for layput, components etc.
*/
/*
@import '~@leanon/fuse2/lib/styles/typography';
@import '~@leanon/fuse2/lib/styles/layout';
@import '~@leanon/fuse2/lib/styles/components/forms';
@import '~@leanon/fuse2/lib/styles/components/header';
@import '~@leanon/fuse2/lib/styles/components/footer';
@import '~@leanon/fuse2/lib/styles/components/menu';
@import '~@leanon/fuse2/lib/styles/components/breadcrumbs';
@import '~@leanon/fuse2/lib/styles/components/forms';
@import '~@leanon/fuse2/lib/styles/components/buttons';
@import '~@leanon/fuse2/lib/styles/components/tables';
@import '~@leanon/fuse2/lib/styles/components/pagination';
@import '~@leanon/fuse2/lib/styles/components/cards';
@import '~@leanon/fuse2/lib/styles/components/modals';
@import '~@leanon/fuse2/lib/styles/components/submenu';
@import '~@leanon/fuse2/lib/styles/components/messages';
@import '~@leanon/fuse2/lib/styles/components/system-status-bar';
@import '~@leanon/fuse2/lib/styles/components/terms-and-conditions';
@import '~@leanon/fuse2/lib/styles/components/accordion';
@import '~@leanon/fuse2/lib/styles/components/tooltip';
@import '~@leanon/fuse2/lib/styles/components/table-accordion';
@import '~@leanon/fuse2/lib/styles/components/karl/account-info';
@import '~@leanon/fuse2/lib/styles/components/karl/investment-plans';
@import '~@leanon/fuse2/lib/styles/components/karl/useraccounts';
@import '~@leanon/fuse2/lib/styles/components/karl/transactions';
@import '~@leanon/fuse2/lib/styles/components/karl/karl-announcements';
@import '~@leanon/fuse2/lib/styles/legacy-mapping/bootstrap3.scss'; // Adapt bootstrap3 classes to bootstrap4
*/
@import 'header';
@import 'footer';
@import 'tables';
@import 'typography';
@import 'register';
@import 'layout';
@import 'utils/index';
@import 'buttons';
@import 'auth';
@import 'alert';
@import 'modals';
@import 'savingsplan';
@import 'card';
@import 'react-widgets';
@import 'components/index';
@import 'forms/index';
// @import 'legacy-mapping/fontawesome';
