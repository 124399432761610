// Accordion
$accordion-header-font-weight: 500;
$accordion-header-color: $black;
$accordion-header-icon-color: $brand-primary;
$accordion-border-bottom: solid 1px $brand-tertiary;

.accordion {
  border-bottom: $accordion-border-bottom;
  .accordion-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
    font-size: 0.875rem;
    font-weight: $accordion-header-font-weight;
    color: $accordion-header-color;
    padding-top: 0.875rem;
    padding-bottom: 0.875rem;
    i {
      color: $accordion-header-icon-color;
      margin: 0;
    }
  }
  .accordion-body {
    padding-top: $accordion-body-padding-y;
    padding-bottom: $accordion-body-padding-y;
  }
}
