.card {
  margin-top: 0.875rem;
  margin-bottom: 1.5rem;
}

.card-title {
  font-size: 1rem;
  font-weight: 200;
  margin-bottom: 0.75rem;
}

.card-white {
  background-color: $white;
}

.card-gray {
  background-color: $gray-100;
}

.card-purple {
  background-color: $gray-200;
}

.card-round {
  border-radius: 10px;
}

.card-shadow {
  box-shadow: 0 25px 20px -20px rgba(43, 3, 89, 0.05);
}

.card-border {
  border: 1px solid $gray-300;
}

.card-active {
  border: 1px solid $brand-primary;
}

.card-extra-space {
  padding-top: 60px;
}

.card-body-slim-padding {
  padding: 1.5rem 1rem;
}

// Accordion
.card-accordion {
  .card-accordion-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
  }
  .card-title {
    margin-bottom: 0;
  }
  .card-accordion-body {
    margin-top: 1.4rem;
    padding: 0;
  }
}

.card-yellow {
  background-color: $yellow1;
  border-color: $yellow2;
}

.card-slim {
  background-color: $white;
  margin-bottom: 1.25rem;
  .card-slim-body {
    padding: 1.5rem;
  }
}

// // Used on fundinfo to get card with full size tabs and white background.
.card-with-tabs {
  .nav-tabs {
    margin-bottom: 1.5rem;
  }
  .nav-item {
    flex: 1;
    text-align: center;
    .nav-link {
      padding-left: 0.4rem;
      padding-right: 0.4rem;
    }
  }

  .card-slim-body {
    padding-top: 0;
  }
}

// Used on fundinfo to combine card and have white background.
.card-accordion {
  background-color: $white;
  padding: 1.25rem;
  border-bottom: none;
  margin-bottom: 1.25rem;
}
