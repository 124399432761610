.toggle {
  line-height: 1;
  cursor: pointer;
  .toggler {
    position: relative;
    display: inline-block;
    width: 58px;
    height: 30px;
    border-radius: 15.5px;
    border: solid 1px $toggler-border-color;
    background-color: #fff;
    &:before {
      content: '';
      position: absolute;
      width: 24px;
      height: 24px;
      background-color: $toggler-color-unchecked;
      border-radius: 100%;
      top: 2px;
      left: 3px;
      transform: translateX(0);
      transition: transform 0.15s ease-in-out;
    }
    .gizmo-toggle-left {
      position: absolute;
      left: 8px;
      top: 50%;
      font-size: 0.875rem !important; // 14px
      transform: translateY(-50%);
    }
    .gizmo-toggle-right {
      position: absolute;
      right: 9px;
      top: 51%;
      font-size: 0.875rem !important; // 14px
      transform: translateY(-50%);
    }
  }
  .toggler.transparent {
    &:before {
      border: solid 1px $toggler-color-transparent-border;
      background-color: $toggler-color-transparent;
    }
  }

  input[type='checkbox']:checked ~ .toggler::before {
    transform: translateX(26px);
    background-color: $toggler-color-checked;
  }
  input[type='checkbox']:checked ~ .toggler.transparent::before {
    transform: translateX(26px);
    background-color: $toggler-color-transparent;
  }
  input[type='checkbox'] {
    opacity: 0;
    position: absolute;
    z-index: 0;
  }
}
