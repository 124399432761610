/**
 * Used by buttons and badges to align icons to right or left of text.
 * To make it work, the text must be wrapped in a html element, eg
 *
 * <a class="icon-positioning">
 *   <span>⭐</span>
 *   <span>Click me!</span>
 * </a>
 *
 * Note: Icon must be placed before text. use .btn-icon-left / .btn-icon-right to switch right/left for single element.
 */

$fuse-icon-placement: right !default; // Globally set icon position

@use 'sass:map';

@mixin icon-positioning($prefix, $spacing) {
  display: inline-flex;
  justify-content: center;
  align-items: baseline;

  @if ($fuse-icon-placement == right) {
    flex-direction: row-reverse;

    > *:not(:only-child):first-child {
      margin-left: $spacing;
    }
  } @else {
    flex-direction: row;
    > *:not(:only-child):first-child {
      margin-right: $spacing;
    }
  }

  &.#{$prefix}-icon-right {
    flex-direction: row-reverse;
    > *:not(:only-child):first-child {
      margin-left: $spacing;
      margin-right: 0;
    }
  }

  &.#{$prefix}-icon-left {
    flex-direction: row;
    > *:not(:only-child):first-child {
      margin-right: $spacing;
      margin-left: 0;
    }
  }
}
