.savingsplan-instruments {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  @include media-breakpoint-down(md) {
    flex-direction: column;
    .table-wrapper {
      width: 100%;
    }
  }
  .table-wrapper {
    flex: 1;
    margin-top: 2.1rem;
  }
  .chart-wrapper {
    width: 220px;
    position: relative;
    text-align: right;
    margin-left: 60px;
    .recharts-wrapper {
      display: inline-block;
    }
    .percentage-sum {
      position: absolute;
      top: 85px;
      left: 35%;
      color: $brand-primary;
      font-size: 30px;
      font-weight: 600;
    }
    .chart-error {
      font-weight: 500;
      text-align: center;
    }
  }
  .table-instrument-amount {
    text-align: center;
  }
  .table-instrument-summary {
    text-align: center;
  }
}

// .user-savings-plan-instruments {
//   .page-header {
//     align-items: flex-end;
//     h2 {
//       margin-bottom: 0;
//     }
//   }
// }

.user-savings-plan {
  .select {
    margin-bottom: 0;
  }
}

.savingslplan-modal-buttons {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  .btn-link-style {
    text-transform: uppercase;
  }
  .btn-default {
    margin-right: 0.5rem;
  }
}
