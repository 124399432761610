// Transactions:
$transactions-date-buttons-margin: 0 -2.5rem 2.5rem -2.5rem;
$transactions-date-buttons-border-bottom: 1px solid $gray-300;
$transactions-date-button-border-color: transparent $gray-300 transparent transparent;
$transactions-date-button-border-color-lg: transparent $gray-300 $gray-300 transparent;
$transactions-date-button-active-border-bottom: 3px solid $gray-200;
$transactions-search-fields-row: true;
$transactions-date-search-fields-margin: 0 0 0 0;

.transaction-search-fields-buttons {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: $transactions-date-buttons-margin;
  border-bottom: $transactions-date-buttons-border-bottom;

  .date-search-fields {
    margin: $transactions-date-search-fields-margin;
  }
  .btn-tab {
    border-color: $transactions-date-button-border-color;
    &.active {
      border-bottom: $transactions-date-button-active-border-bottom;
    }
    @include media-breakpoint-down(lg) {
      border-color: $transactions-date-button-border-color-lg;
    }
  }
  @if $transactions-search-fields-row {
    .form-group {
      display: flex;
      flex-direction: row;
      align-items: center;
      margin-bottom: 0;
      label {
        margin-right: 5px;
      }
      &:last-child {
        @include media-breakpoint-down(md) {
          margin-left: 10px;
          margin-top: 0;
        }
      }
    }
  }
  @include media-breakpoint-down(lg) {
    flex-direction: column;
    .date-buttons {
      width: 100%;
      display: flex;
      margin-bottom: 1rem;
    }
    .date-button {
      flex: 1;
      padding-left: 0;
      padding-right: 0;
    }
    .date-search-fields {
      justify-content: flex-end;
      width: 100%;
      margin-top: 1rem;
      margin-bottom: 1rem;
    }
  }
  @include media-breakpoint-down(md) {
    margin: 0;
    .date-buttons {
      border-bottom: unset;
    }
  }
}
.date-search-fields {
  display: flex;
}

.transaction-search-fields-buttons {
  border-top: 1px solid $gray-300;
  label {
    display: none;
  }
  .rw-widget-picker {
    height: 56px;
    min-height: 0;
  }
  .rw-widget {
    .rw-calendar-popup {
      left: auto;
      right: -0;
      min-width: 200px;
      padding-right: 0;
      @include media-breakpoint-down(md) {
        max-width: 280px;
      }
    }
  }
  @include media-breakpoint-up(xl) {
    .rw-widget-picker {
      border-top: none;
      border-bottom: none;
      border-right: none;
    }
  }
  @include media-breakpoint-up(md) {
    .date-button {
      font-size: 0.875rem;
      border: none;
    }
  }
}
