.table {
  @extend .table-striped;
  margin-bottom: 2.25rem;
  th {
    font-size: 0.75rem;
    // background-color: $white;
    box-shadow: none;
    a {
      font-weight: $font-weight-base;
    }
  }
  td {
    font-size: 0.85rem;
  }
  .checkbox,
  .checkbox.checkbox-checked {
    background-color: transparent;
    border-left: none;
    label {
      padding-top: 0;
      padding-bottom: 0;
      &:before {
        background-color: $white;
      }
    }
  }
  tfoot td {
    box-shadow: none;
  }
  &.table-sm {
    td {
      padding: 0.5rem 1rem;
    }
  }
}

.table-response {
  width: 100%;
  overflow-x: auto;
}

.table-hover {
  tr {
    cursor: pointer;
  }
}

.table-editable td:last-child {
  vertical-align: middle;
}

// Instrument Picker in Savingsplan
.table-instrument-picker {
  th:nth-child(2),
  td:nth-child(2) {
    width: 70px;
  }
  .table-instrument-percent {
    color: $white;
    padding: 0.5rem 1.4rem;
    text-align: center;
    font-weight: 600;
  }
}

.table-wrapper {
  .text-muted {
    margin-bottom: 0;
  }
}

.table-bold {
  font-weight: 500;
}

.table-blocks {
  table {
    border-spacing: 0 10px;
    border-collapse: separate;
    background-color: transparent;
  }
  td {
    background-color: $white;
    border-top: 1px solid $gray-300;
    border-bottom: 1px solid $gray-300;
    padding-top: 1.1rem;
    padding-bottom: 1.1rem;
    &:first-child {
      border-left: 1px solid $gray-300;
      padding-left: 2rem;
    }
    &:last-child {
      border-right: 1px solid $gray-300;
      padding-right: 2rem;
    }
  }
  .highlighted-content {
    font-size: 1.14rem;
    font-weight: 500;
  }
}

table thead {
  .th-bold {
    font-weight: 600;
  }

  .th-bigger {
    font-size: 1rem;
  }

  .th-black {
    color: #000;
  }
}

.table-vertical-align-middle {
  table td {
    vertical-align: middle;
  }
}
