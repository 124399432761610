$iris-size: 60px;

.iris-popup {
  position: fixed;
  bottom: 10px;
  right: 10px;

  @include media-breakpoint-down(sm) {
    display: none;
  }

  .iris-btn {
    position: relative;
    background: transparent;
    border: none;
    color: $white;
    &:focus {
      outline: none;
    }
  }
  .iris-toggle-btn {
    width: $iris-size;
    height: $iris-size;
  }
  .iris-overlay {
    position: absolute;
    width: $iris-size;
    height: $iris-size;
    top: 0;
    left: 0;
    background-color: $brand-primary;
    border-radius: 100%;
    display: none;
    justify-content: center;
    align-items: center;
    font-size: 1.57rem;
  }

  &.iris-open {
    .iris-image {
      opacity: 0;
    }
    .iris-overlay {
      display: flex;
    }
  }

  .iris-modal {
    position: absolute;
    right: 0px;
    bottom: 80px;
    width: 320px;
    border: 1px solid $brand-tertiary;
    background-color: $white;
  }
  .iris-modal-header {
    position: relative;
    text-align: center;
    background-color: $brand-primary;
    color: $white;
    padding: 1.14rem 0.79rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    h3 {
      margin-bottom: 0;
    }
  }
  .iris-modal-body {
    padding: 2.375rem 2rem 2.2rem;
    text-align: center;
    .iris-modal-img {
      width: $iris-size;
      height: $iris-size;
      margin-bottom: 0.7rem;
    }
  }

  .iris-links {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  $iris-block-size: 125px;

  .iris-link {
    display: inline-block;
    max-width: $iris-block-size;
    p {
      font-weight: 200;
      color: $body-color;
    }
  }

  .iris-link-block {
    width: $iris-block-size;
    height: $iris-block-size;
    background-color: $gray-200;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
