$search-field-form-group-margin-bottom: 0.5rem;

.search-field {
  width: 190px;
  &.mc-sustainability {
    width: 260px;
  }
}

.search-field-label {
  font-weight: $form-label-font-weight;
  margin-bottom: 0.5rem;
}

// Used to wrap the table search inputs
.search-field-wrapper:not(:empty),
.gizmo-search-form {
  display: flex;
  align-items: flex-end;
  margin: (2 * $spacer) 0 0.5 * $spacer 0;
  flex-wrap: wrap;
  .form-group {
    padding-right: $spacer; // Make search inputs have spacing
    margin-bottom: $search-field-form-group-margin-bottom;

    // Add a search icon on top of input field
    &.with-search-icon {
      position: relative;
      &:after {
        content: '\f002';
        color: $brand-primary;
        font-family: 'Font Awesome 5 Pro';
        position: absolute;
        font-weight: 300;
        top: 5px;
        right: 30px;
        font-size: 21px;
      }
      .form-control {
        padding-right: 40px;
      }
    }
  }

  & > .btn {
    margin-right: $spacer; // Make button filters have spacing
  }

  .btn-group:empty {
    display: none; // Fuse creates empty .btn-groups sometimes, that take up invisible space.
  }

  .btn-row {
    margin-bottom: $search-field-form-group-margin-bottom; // Makes buttons align with inputs vertically

    > .btn,
    > .btn-group {
      // Make search and export buttons have spacing
      margin-right: $spacer;
    }
  }

  // Make search fields smaller than other forms
  .rw-widget-picker,
  .form-control,
  .btn {
    $input-height-slim: 50px;
    height: $input-height-slim;
    min-height: $input-height-slim;
  }
  .btn {
    padding: 0.9rem $btn-padding-x;
  }
}
